import { ROUTES, ROUTES_QUERY } from "@shared/interfaces";
import styles from "./styles.module.css";

import Female from "@assets/user/female.svg";
import FemaleActive from "@assets/user/female_active.svg";
import Male from "@assets/user/male.svg";
import MaleActive from "@assets/user/male_active.svg";
import Anime from "@assets/user/anime.svg";
import AnimeActive from "@assets/user/anime_active.svg";
import { HeaderNavItem } from "@features/header/nav";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "@shared/libs";
import { IHeaderNavItem } from "../model/headerNav.interfaces";
import Burger from "@assets/burger_white.svg";
import LOGO_MAIN from "@assets/logo_main.svg";

const headerNav: IHeaderNavItem[] = [
  {
    path: `type=${ROUTES_QUERY.female}`,
    title: "Girls",
    icon: Female,
    activeIcon: FemaleActive,
    key: ROUTES_QUERY.female,
  },
  {
    path: `type=${ROUTES_QUERY.male}`,
    title: "Guys",
    icon: Male,
    activeIcon: MaleActive,
    key: ROUTES_QUERY.male,
  },
  // {
  //   path: `type=${ROUTES_QUERY.anime_female}`,
  //   title: "Anime girls",
  //   icon: Anime,
  //   activeIcon: AnimeActive,
  //   key: ROUTES_QUERY.anime_female,
  // },
  // {
  //   path: `type=${ROUTES_QUERY.anime_male}`,
  //   title: "Anime guys",
  //   icon: Anime,
  //   activeIcon: AnimeActive,
  //   key: ROUTES_QUERY.anime_male,
  // },
];

const ALLOWED_PAGES = [
  ROUTES.BASE,
  ROUTES.HOME,
  ROUTES.GALLERY,
  ROUTES.SUBSCRIBE,
];

interface IProps {
  setShowAside?: (flag: boolean) => void;
}

const HeaderNav = ({ setShowAside }: IProps) => {
  let { search, pathname } = useLocation();
  const params = url.getParams(search, "type");
  const navigate = useNavigate();

  const isNavbarShow = ALLOWED_PAGES.find((url) => url === pathname);
  const classes = [styles.header_nav_container];

  if (!isNavbarShow) {
    classes.push(styles.header_nav_container_hidden);
  }

  const isActive = (key: string) => {
    if (!params && key === ROUTES_QUERY.female) {
      return true;
    }

    if (!params || params !== key) {
      return false;
    }

    return true;
  };

  if (pathname === ROUTES.SUBSCRIBE) {
    pathname = ROUTES.BASE;
  }

  return (
    <div className={classes.join(" ")}>
      <img
        src={Burger}
        alt="burger"
        onClick={() =>setShowAside && setShowAside(true)}
        className={styles.header_burger}
      />

      <img
        src={LOGO_MAIN}
        alt="main_logo"
        onClick={() =>  navigate(ROUTES.BASE)}
        className={styles.header_logo}
      />
      {headerNav.map((nav: IHeaderNavItem) => (
        <HeaderNavItem
          item={nav}
          key={nav.key}
          pathname={pathname}
          isActive={isActive(nav.key)}
        />
      ))}
    </div>
  );
};

export default HeaderNav;
