import React from "react";

export enum ROUTES_QUERY {
  male = "male",
  female = "female",
  anime_male = "anime_male",
  anime_female = "anime_female",
}

export enum ROUTES {
  BASE = "/",
  HOME = "/explore",
  SUBSCRIBE = "/subscribe",
  PLANS = "/plans",
  REGISTER = "/register",
  LOGIN = "/login",
  RESET = "/reset",
  GALLERY = "/gallery",
  ADMIN = "/admin",
  CHAT = "/chat",
  CHAT_ID = "/chat/:id",
  RESET_PASSWORD = "/restore",
  RESTORE_PASSWORD = "/restore",
  SETTINGS = "/settings",
  PAYMENT = "/payment",
  NOT_FOUND = "*",
}

export interface IRoute {
  path: ROUTES;
  element: React.ReactElement;
}
