import { IModelState } from "@entities/model/model/model.types";
import styles from "./styles.module.css";
import { useModelStore } from "@entities/model/model/model.store";
import GalleryItemFactory from "@features/gallery/GalleryItem.fabric";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Portal } from "@widgets/portal";
import GalleryItemPreview from "./GalleryItemPreview";
import { IUserState, useUserStore } from "@entities/user";
import { ROUTES } from "@shared/interfaces";

const Gallery = () => {
  const [activeItem, setActiveItem] = useState<any>(null);
  const { gallery } = useModelStore((state: IModelState) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useUserStore((state: IUserState) => state.user);
  const navigate = useNavigate();
  const [galleryState, setGalleryState] = useState<any[]>(gallery);

  const activeSearchParams = Object.fromEntries(searchParams.entries());
  const previewId = activeSearchParams?.preview;

  useEffect(() => {
    if (!previewId || !gallery.length) {
      return;
    }

    const activeModel = gallery.find((item) => item.id === +previewId);

    if (!activeModel?.id) {
      return;
    }

    if (!user.plan) {
      navigate(ROUTES.PLANS);
    }

    setActiveItem(activeModel);
  }, [previewId, gallery.length]);

  const onClosePreview = () => {
    setActiveItem(null);
    searchParams.delete("preview");
    setSearchParams(searchParams);
  };

  if (!gallery.length) {
    return <></>;
  }

  return (
    <div className={styles.gallery_container}>
      {activeItem && (
        <Portal className="portal_home">
          <GalleryItemPreview
            url={activeItem?.photo || ""}
            onClose={() => onClosePreview()}
          />
        </Portal>
      )}
      {gallery.map((model) => (
        <GalleryItemFactory model={model} key={model.id} />
      ))}
    </div>
  );
};

export default Gallery;
