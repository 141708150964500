import styles from "./styles.module.css";

import { ROUTES } from "@shared/interfaces";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Info from "@assets/info_blue.svg";
import Apple from "@assets/apple.svg";
import Google from "@assets/google.svg";
import Check from '@assets/check_black.svg';
import Visa from '@assets/visa.svg';
import Master from '@assets/master.svg';
import Arrow from "@assets/arrow_long.svg";
import FooterLogo from "@assets/footer_payment.svg";
import { ButtonFactory } from "@shared/ui/Button";
import { InputItem } from "@shared/ui/Inputs";


const PaymentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  document.body.className = "payment";

  const activeSearchParams = Object.fromEntries(searchParams.entries());
  let price = activeSearchParams?.price;

  if (!price) {
    price = '5.99';
  }

  useEffect(()=> {
    // return () => {
    //   document.body.className = "";
    // }
  }, []);

  const defineErrorType = (value: string) => {
    // if (!activeFormError) {
    //   return;
    // }

    // const firstError = Object.keys(activeFormError)[0];

    // if (!firstError || firstError !== value) {
    //   return null;
    // }
    
    // return activeFormError[value];
  };

  return (
    <div className={styles.payment__container}>
      <div className={styles.alert__container}>
        <img src={Info} alt="user_profile" />
        <h5 className={styles.alert__text}>
          All communications and notifications regarding applied changes will 
          be via email.
        </h5>
      </div>

      <div className={styles.payment__order}>
        <div className={styles.payment_order}>
          <h4>Order summary</h4>
        </div>
        <div className={styles.payment_order_content}>
          <h5>GetFlirt AI</h5>
          <span>Premium Plan (70% OFF)</span>
        </div>

        <div className={styles.payment_order_total}>
          <h5>Total</h5>
          <h5><span>$</span>{price}</h5>
        </div>
      </div>

      <div className={styles.payment_method}>
        <h5>Payment method</h5>

        <div className={styles.payment_btn}>
          <ButtonFactory
            variant="primary"
            type="button"
            label=""
            onClick={()=>{}}
            children = {
              <div className={styles.pay_button_wrapper}>
                <img src={Apple} alt="apple" />
                <h5>Pay</h5>
              </div>
            }
          />
          <ButtonFactory
            variant="primary"
            label="Pay"
            type="button"
            onClick={() => {}}
            children = {
              <div className={styles.pay_button_wrapper}>
                <img src={Google} alt="google" />
                <h5>Pay</h5>
              </div>
            }
          />
        </div>
        <div className={styles.payment_divider_container}>
          <div className={styles.payment_divider}></div>
          <h5>Or</h5>
          <div className={styles.payment_divider}></div>
        </div>
      </div>

      <div className={styles.cards}>
        <div className={styles.card_title}>
          <div className={styles.title_left}>
            <img src={Check} alt="check" />
            <h5>Pay with Credit Card</h5>
          </div>
          <div className={styles.card__items}>
            <img src={Visa} alt="visa" />
            <img src={Master} alt="check" />
          </div>
        </div>
        <div className={styles.card_info}>
          <div className={styles.carg_info_left}>
            <h5>Name on card</h5>
            <InputItem
              type="email"
              name= "email"
              onChange={()=>{}}
              value={""}
              placeholder = "Cardholder name and surname"
              //error={defineErrorType('email')}
            />
          </div>
          <div className={styles.carg_info_right}>
              <h5>Expiry</h5>
              <InputItem
                type="email"
                name= "email"
                onChange={()=>{}}
                value={""}
                placeholder = "MM/YY"
                //error={defineErrorType('email')}
              />
          </div>
        </div>

        <div className={styles.card_info}>
          <div className={styles.carg_info_left}>
            <h5>Card number</h5>
            <InputItem
              type="email"
              name= "email"
              onChange={()=>{}}
              value={""}
              placeholder = "Card number"
              //error={defineErrorType('email')}
            />
          </div>
          <div className={styles.carg_info_right}>
              <h5>CVV</h5>
              <InputItem
                type="email"
                name= "email"
                onChange={()=>{}}
                value={""}
                placeholder = "CVC"
                //error={defineErrorType('email')}
              />
          </div>
        </div>
      </div>
      <div className={styles.confirm}>
        <ButtonFactory
          variant="primary"
          label="Confirm"
          type="button"
          onClick={() => {}}
        />
      </div>

      <Link className={styles.return} to={ROUTES.PLANS}>
        <img src={Arrow} alt="arrow" />
        Return
      </Link>

      <div className={styles.payment_divider}></div>

      <div className={styles.payment__footer}>
        <h5>GetFlirt AI Ltd</h5>

        <div className={styles.footer_content}>
          <h5>Powered by</h5>
          <img src={FooterLogo} alt="footer_logo" />
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
