import styles from "./styles.module.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RouterBuilder } from "./config/routes";
import { IUserState, useUserStore } from "@entities/user";
import { Portal } from "@widgets/portal";
import { Interested } from "@widgets/interested";
import { UserAuthContextProvider } from "@shared/model";
import { ErrorBoundary } from "react-error-boundary";
import { IServerMessage, ROUTES } from "@shared/interfaces";
import { SkeletonTheme } from 'react-loading-skeleton';
import { useEffect } from "react";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import 'react-loading-skeleton/dist/skeleton.css'

const PING_PONGINTERVAL = 30000;
const INTERVAL = 1500;

const App: React.FC = () => {
  const user = useUserStore((state: IUserState) => state.user);
  const isAuth = user.id && user.id !== "initialising";

  const {
    setMessage,
    setIsTyping,
    addChatFirst,
    setDisableBtn,
    setLastMessage,
    setGenerationProgress
  } = useModelStore((state: IModelState) => state);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    socketConnection();
  }, [isAuth]);

  useEffect(() => {
    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'visible' && (window as any)?.socket?.readyState !== WebSocket.OPEN) {
        console.log('Tab is active. Checking WebSocket connection...');
        socketConnection();
      }
    });
  }, [])

  let routes = new RouterBuilder().build();

  if (!isAuth) {
    routes = new RouterBuilder().addAuthRouters().build();
  }

  const router = createBrowserRouter(routes);

  const renderPortal = () => {
    const allowsRoutes: string[] = [
      ROUTES.GALLERY,
      ROUTES.HOME,
      ROUTES.CHAT,
      ROUTES.SUBSCRIBE,
    ];

    const isStartedChoosing = localStorage.getItem("isStartedChoosing");

    const pathName = window.location.pathname;
    const finedRouter = allowsRoutes.some((route) => route.includes(pathName));

    if (
      (!isStartedChoosing && finedRouter) ||
      (!isStartedChoosing && /^\/chat\/[0-9]$/.test(pathName) === true) ||
      (!isStartedChoosing && /^\/chat\/\$/.test(pathName) === true)
    ) {
      return (
        <Portal className="portal_home">
          <Interested />
        </Portal>
      );
    }
  };

  const isOpen=(ws: any) => { 
    return ws?.readyState === ws?.OPEN
  }

  const socketConnection = () => {
    const { REACT_APP_WS_URL } = process.env;
    const token = localStorage.getItem('_accessToken');

    if (!isOpen((window as any).socket)) {
      clearInterval((window as any).interval);
      (window as any).socket.close();
    };

    (window as any).socket = new WebSocket(
      `${REACT_APP_WS_URL}/messages?token=${token}`,
     );
    
     (window as any).socket.onopen = () => {
      (window as any).interval = null;

      clearInterval((window as any).interval);
      console.log(`[WSS]: connected`);

      setPingPongConnection();
    };

    (window as any).socket.onmessage = (message:any) => {
      const gettingMessage = message.data;

      if (gettingMessage === "pong") {
        return;
      }
      
      getMessages(JSON.parse(gettingMessage));
    }

    (window as any).socket.onclose = (e:any) => {
      console.log(
        '[WS]: socket is closed.',
        e.reason
      );

      (window as any).interval = setTimeout(() => {
        socketConnection()
      }, INTERVAL);
    };

    (window as any).socket.onerror = (e:any) => {
      console.log("[WS]: socket is closed.", e);

      (window as any).socket.close();
    };

    const setPingPongConnection = () => {
      console.log(`[WSS]: ping-pong connection`);
      //TODO clear interval
      clearInterval((window as any).intervalID);
      (window as any).intervalID = null;

      (window as any).intervalID = setInterval(() => {
        if (!isOpen((window as any).socket)) return;
        (window as any).socket.send('ping');
      }, PING_PONGINTERVAL);
    };
  };

  const getMessages = (message: any) => {
    console.log("====>Callback", message);
    messagesGateWay(message);
  };

  const messagesGateWay = (message: IServerMessage) => {
    const chatId = +message.chat_id;

    if (message.type === "typing") {
      return setIsTyping(true);
    }

    if (message.type === "start_generation") {
      return setGenerationProgress(true);
    }

    setIsTyping(false);
    setGenerationProgress(false);
    addChatFirst(chatId);
    setLastMessage(chatId, message);
    setDisableBtn(false);

    if (message.type === 'image' || message.type === 'text') {

      return setMessage(message);
    }
  }

  return (
    <>
      {renderPortal()}
      <RouterProvider router={router} />
    </>
  );
};

const AppWithProvider: React.FC = () => {
  return (
    <div className={styles.root__container}>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <UserAuthContextProvider>
          <SkeletonTheme baseColor="#2C2C33" highlightColor="#1E1E23" duration={1.2}>
            <App />
          </SkeletonTheme>
        </UserAuthContextProvider>
      </ErrorBoundary>
    </div>
  );
};

export default AppWithProvider;
