import { Search } from "@shared/ui/Search";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { IChatItem } from "@shared/interfaces";
import { ChatBarItem, ChatBarSkeleton } from "@features/model/ui";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IUserState, useUserStore } from "@entities/user";
import { useDebouncedCallback } from "use-debounce";

interface IProps {
  isDesktop: boolean;
  setSteps: (step: string) => void;
}

const ChatBar: React.FC<IProps> = ({ isDesktop, setSteps }: IProps) => {
  let { id } = useParams();
  let { search } = useLocation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  const user = useUserStore((state: IUserState) => state.user);
  const isAuth = user.id !== null;
  const isInit = user.id === "initialising";

  const {
    saveChat,
    fetchModel,
    setMessages,
    setActiveModel,
    setModelsChats,
    getChatMessages,
    addEmptyMessage,
    fetchModelsChats,
    setModelChatsLoading,
    setModelActiveLoading,
    addModelToModelsChat,
    fetchModelsChatsAndSetFirstActiveModel,
  } = useModelStore((state: IModelState) => state);

  const chats = useModelStore((state: IModelState) => state.modelsChats);
  const { model } = useModelStore((state: IModelState) => state.modelActive);
  const isModelsChatsLoading= useModelStore((state: IModelState) => state.isModelsChatsLoading);
  
  const gender = localStorage.getItem("isStartedChoosing");

  const activeChat =
  chats && chats.find((chat: IChatItem) => chat.model.id === model.id);

  useEffect(() => {
    async function fetchAPI() {
      if (isInit) {
        return <></>;
      }

      if (isAuth) {
        setModelChatsLoading(true);
        setModelActiveLoading(true)
        if (id) {
          await saveChat(id);
          await fetchModelsChats(search, true);
          await getChatMessages(id as string);
        } else {
          await fetchModelsChatsAndSetFirstActiveModel(search);
        }
      } else {
        await fetchModel(id as string);
        addModelToModelsChat();
        addEmptyMessage();
      }

      setModelChatsLoading(false);
      setModelActiveLoading(false)
    }

    fetchAPI();

    return () => {
      setModelsChats();
      setActiveModel();
      setMessages([]);
    };
  }, [isInit, isAuth]);

  useEffect(() => {
    async function fetchAPI() {
      if (isInit || !id) {
        return;
      }

      await fetchModel(id as string);
      await getChatMessages(id as string);
    }

    fetchAPI();
  }, [id]);

  const debounced = useDebouncedCallback(async (value) => {
    let search = `${gender}&search=${value}`;

    if (!value || value === "") {
      searchParams.delete("search");
      search = `${gender}`;
    } else {
      searchParams.set("search", value);
    }

    setSearchParams(searchParams);
    await fetchModelsChatsAndSetFirstActiveModel(search);
  }, 1000);

  const onSearchChange = (value: string) => {
    setSearchValue(value);
    debounced(value);
  };

  const defineActive = (chatId: number | undefined) => {
    return model.id === chatId;
  };

  return (
    <div
      className={`${styles.chat_bar_container} ${
        isDesktop && styles.chat_bar_adaptive
      }`}
    >
      <h4>Chat</h4>
      {isAuth && (
        <div className={styles.chat_bar_search_container}>
          <Search value={searchValue} onChange={onSearchChange} />
        </div>
      )}
      <div className={styles.chat_bar_wrapper}>
      { isModelsChatsLoading ? 
        <ChatBarSkeleton cards={4}/> :
        chats?.length ? (
          chats.map((chat: IChatItem) => (
            <ChatBarItem
              key={chat.id}
              model={chat.model}
              last_message={chat.last_message}
              isActive={isDesktop && defineActive(chat.model.id)}
              isDesktop={isDesktop}
            />
          ))
        ) : (
          <div className={styles.nothing}>
            <h6>Nothing was found :(</h6>
          </div>
        )
      }
      </div>
    </div>
  );
};

export default ChatBar;
