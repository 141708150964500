import { sortByDateDesc, url } from "@shared/libs";
import { IModelState } from "./model.types";
import { axiosInstance } from "@shared/utils/configs/axiosInstance";
import { create } from "zustand";
import {
  IChatItem,
  IGalleryFilter,
  IMessage,
  IModel,
  ROUTES,
} from "@shared/interfaces";

const lastMessage: IMessage = {
  from: "",
  images_urls: undefined,
  send_at: "",
  text: "",
};

const model: IModel = {
  id: undefined,
  name: "",
  description: "",
  user_first_msg_help: "",
  first_msg: "",
};

const modelActive: IChatItem = {
  id: null,
  model,
  last_message: lastMessage,
};

const galleryFilter: IGalleryFilter = {
  items: [],
  pages_count: 0,
  total_count: 0,
};

export const useModelStore = create<IModelState>((set, get) => {
  return {
    models: [],
    gallery: [],
    messages: [],
    modelsChats: [],
    isTyping: false,
    disabledBtn: false,
    modelActive: modelActive,
    isModelsChatsLoading: true,
    isModelActiveLoading: true,
    galleryFilters: galleryFilter,
    isGenerationInProgress: false,
    setDisableBtn: (state: boolean) => {
      set({ disabledBtn: state });
    },
    fetchGallery: async (search: string) => {
      const adaptedSearch = url.adaptParams(search);
      try {
        const url = "api/gallery" + adaptedSearch;
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<{ items: IModel[] }>(
          url,
          axiosConfig
        );

        set({ gallery: data.items });
      } catch (e) {
        console.log(e);
      }
    },
    fetchGalleryFilters: async () => {
      try {
        const url = "api/gallery/models";
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<IGalleryFilter>(
          url,
          axiosConfig
        );

        set({ galleryFilters: data });
      } catch (e) {
        console.log(e);
      }
    },
    fetchModels: async (search: string) => {
      const adaptedSearch = url.adaptParams(search);
      try {
        const url = "api/models" + adaptedSearch;
        const { data } = await axiosInstance.get<{ items: IModel[] }>(url);

        set({ models: data.items });
      } catch (e) {
        console.log(e);
      }
    },
    fetchModelsChats: async (search = "", flag?: boolean) => {
      const adaptedSearch = url.adaptParams(search);
      try {
        const url = "/api/chats" + adaptedSearch;

        const headers: any = {};

        if (flag) {
          headers["Authorization"] = localStorage.getItem("_accessToken");
        }

        const { data } = await axiosInstance.get<{ items: any[] }>(url, {
          headers,
        });

        if (!data.items.length && !flag) {
          return await get().fetchAnonimusModelsChats();
        }

        const sortedItem = sortByDateDesc(data.items);

        set({ modelsChats: sortedItem });
      } catch (e) {
        console.log(e);
      } finally {
        get().setModelChatsLoading(false);
      }
    },
    fetchModelsChatsAndSetFirstActiveModel: async (search?: string) => {
      try {
        const url = "/api/chats" + search;
        const { data } = await axiosInstance.get<{ items: any[] }>(url, {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        });

        if (!data.items.length && !search) {
          return await get().fetchAnonimusModelsChats();
        }

        if (!data.items.length && search) {
          return set({ modelsChats: data.items });
        }

        const sortedItem = sortByDateDesc(data.items);
        const chat = sortedItem[0];
        chat.model.first_msg = chat.last_message.text;

        set({ modelsChats: sortedItem });
        set({ modelActive: sortedItem[0] });
        await get().getChatMessages(chat.model.id);
      } catch (e) {
        console.log(e);
      }
    },
    fetchAnonimusModelsChats: async () => {
      let search = localStorage.getItem("isStartedChoosing");

      if (!search) {
        search = "?type=female";
      }

      const adaptedSearch = url.adaptParams(search);

      try {
        const url = "/api/chats" + adaptedSearch;
        const { data } = await axiosInstance.get<{ items: any[] }>(url, {
          headers: {
            Authorization: null,
          },
        });

        const message = {
          from: "model",
          image_url: "",
          send_at: new Date().toLocaleString().replace(",", "").slice(11, 16),
          text: data.items[0].model.first_msg,
          type: "empty",
        };

        if (data.items.length) {
          set({ modelsChats: data.items });
          set({ modelActive: data.items[0] });
          set({ messages: [message] });
        }
      } catch (e) {
        console.log(e);
      }
    },
    fetchModel: async (id: string) => {
      try {
        const url = `/api/models/${id}`;
        const { data } = await axiosInstance.get<any>(url);

        var date = new Date();

        const lastMessageWithText = {
          from: "model",
          image_url: "",
          send_at: date.toISOString(),
          text: data.first_msg,
        };

        set({
          modelActive: { model: data, last_message: lastMessageWithText },
        });
      } catch (e) {
        console.log(e);
      }
    },
    setModelsChats: (modelsChats?: any[]) => {
      if (!modelsChats) {
        set({ modelsChats });
      } else {
        set({ modelsChats: undefined });
      }
    },
    addChatFirst: (id: number) => {
      const modelsChats = get().modelsChats;
      const existedChat = modelsChats?.find((chat) => chat.id === id);

      if (existedChat) {
        let modelsChatFiltered =
          modelsChats?.filter((chat) => chat.id !== existedChat.id) || [];

        set({ modelsChats: [existedChat, ...modelsChatFiltered] });
      }
    },
    saveChat: async (id: string) => {
      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const body = {
        model_id: +id,
      };

      try {
        const url = `/api/chats`;
        const { data } = await axiosInstance.post<{ chat: any }>(
          url,
          body,
          axiosConfig
        );

        set({ modelActive: data.chat });
        await get().addModelToModelsChatWithParams(data.chat);
      } catch (e) {
        console.log(e);
      }
    },
    setModels: (models: []) => {
      set({ models: models });
    },
    setActiveModel: () => {
      set({
        modelActive: {
          model: model,
          last_message: lastMessage,
        },
      });
    },
    addModelToModelsChat: () => {
      const chats = get().modelsChats || [];

      const activeModel = get().modelActive;
      set({
        modelsChats: [{ id: 0, ...activeModel }, ...chats],
      });
    },
    addModelToModelsChatWithParams: (activeModel: any) => {
      const chats = get().modelsChats || [];

      set({
        modelsChats: [{ id: 0, ...activeModel }, ...chats],
      });
    },
    setLastMessage: (chatId: any, message: any) => {
      if (!chatId) {
        return;
      }

      const chats = get().modelsChats;

      set({
        modelsChats: chats?.map((chat) => {
          if (chatId === chat.id) {
            chat.last_message = message;
          }

          return chat;
        }),
      });
    },
    getChatMessages: async (modelId: string) => {
      get().setDisableBtn(true);
      await get().setMessages(null);

      const chats = get().modelsChats || [];

      const activeChat = chats.find(
        (chat: IChatItem) => chat.model.id === +modelId
      );

      if (!activeChat) {
        return;
      }

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const url = `/api/chats/${activeChat.id}/messages?limit=200`;
      const { data } = await axiosInstance.get<any>(url, axiosConfig);

      const items = data.items;
      if (items.length === 1 && items[0].text === "") {
        items[0].text = activeChat.model.first_msg;
      }

      set({ messages: items, disabledBtn: false });
    },
    sendMessage: async (chatId: string, message: string) => {
      const messages = get().messages || [];

      const newMessage = {
        from: "user",
        image_url: "",
        send_at: new Date().toLocaleString().replace(",", "").slice(11, 16),
        text: message,
      };

      try {
        const body = {
          message: {
            text: message,
          },
        };

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        set({ messages: [...messages, newMessage] });

        const url = `/api/chats/${chatId}/messages`;
        await axiosInstance.post<any>(url, body, axiosConfig);
      } catch (e) {
        console.log(e);
      }
    },
    setMessages: (messages: any[] | null) => {
      set({ messages: messages });
    },
    setMessage: async (message: any) => {
      const messages = get().messages || [];
      const chats = get().modelsChats || [];
      const currentModel = get().modelActive || [];

      const currentChat = chats.find(
        (chat) => chat.model.id === currentModel.model.id
      );

      if (!currentChat) {
        return;
      }

      if (currentChat.id === +message.chat_id) {
        set({ messages: [...messages, message] });
      }
    },
    addEmptyMessage: () => {
      const activeModel = get().modelActive;

      const message = {
        from: "model",
        image_url: "",
        send_at: new Date().toLocaleString().replace(",", "").slice(11, 16),
        text: activeModel.model?.first_msg || "",
      };

      set({ messages: [message] });
    },
    restartChat: async (chatId: string) => {
      try {
        try {
          const axiosConfig = {
            headers: {
              Authorization: localStorage.getItem("_accessToken"),
            },
          };

          const url = `/api/chats/${chatId}/messages/delete_all`;

          await axiosInstance.put<any>(url, {}, axiosConfig);
          set({ messages: [] });
          get().setLastMessage(+chatId, lastMessage);
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    },
    deleteChat: async (chatId: string) => {
      try {
        const url = "/api/chats/" + chatId;
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        await axiosInstance.delete<{ items: IModel[] }>(url, axiosConfig);

        const chats = get().modelsChats;

        set({
          modelActive: modelActive,
          modelsChats: chats?.filter((chat) => chat.id !== +chatId),
        });
      } catch (e) {
        console.log(e);
      }
    },
    deleteMessage: async (id: string, chatId: string) => {
      try {
        const url = "/api/chats/" + chatId + "/messages/delete";
        const body = {
          msgs_ids: [id],
        };

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        await axiosInstance.put<{ items: IModel[] }>(url, body, axiosConfig);

        const messages = get().messages?.filter((item) => item.id !== id);

        set({ messages: messages });
      } catch (e) {
        console.log("--->", e);
      }
    },
    setModelChatsLoading: (flag) => {
      set({ isModelsChatsLoading: flag });
    },
    setModelActiveLoading: (flag) => {
      set({ isModelActiveLoading: flag });
    },
    setIsTyping: (flag: boolean) => {
      const chats = get().modelsChats || [];
      const currentModel = get().modelActive || [];

      const currentChat = chats.find(
        (chat) => chat.model.id === currentModel.model.id
      );

      if (!currentChat) {
        return;
      }

      set({ isTyping: flag });
    },
    setGenerationProgress: (flag: boolean) => {
      set({ isGenerationInProgress: flag });
    },
  };
});
