import styles from "./styles.module.css";

interface IProps {
  img: string | undefined;
  type?: string;
}

const ModelMessageImg = ({ img, type }: IProps) => {
  const classes = [styles.message_wrapper];

  if (!img) {
    return <></>;
  }

  if (type === "model") {
    classes.push(styles.message_model);
  }

  if (type === "user") {
    classes.push(styles.message_self);
  }

  return (
    <div className={classes.join(" ")}>
      <div className={styles.message_container}>
        <div className={styles.message_wrapper}>
          <img src={img} className={styles.message_img} alt="model_message_img"/>
        </div>
      </div>
    </div>
  );
};

export default ModelMessageImg;
