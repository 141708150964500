import styles from "./styles.module.css";
import { useEffect } from "react";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { useLocation, useNavigate } from "react-router-dom";
import GalleryItemFactory from "@features/gallery/GalleryItem.fabric";
import { IModel } from "@shared/interfaces";
import { HeaderNav } from "@widgets/header_nav";

const HomeGallery: React.FC = () => {
  let { search } = useLocation();
  const navigate = useNavigate();
  const { fetchModels, setModels } = useModelStore(
    (state: IModelState) => state
  );
  const models = useModelStore((state: IModelState) => state.models);
  const choosedCharacterBefore = localStorage.getItem("isStartedChoosing");

  if (!search) {
    search = choosedCharacterBefore ? choosedCharacterBefore : "?type=female";

    setTimeout(() => navigate(search), 300);
  }

  useEffect(() => {
    async function fetchAPI() {
      await fetchModels(search);
    }
    fetchAPI();

    return () => {
      setModels([]);
    };
  }, [search, fetchModels, setModels]);

  if (!models?.length) {
    return <></>;
  }

  const modelsWithoutFullNAme = models.forEach((model) => {
    const firstName = model.name?.split(" ")[0];

    if (firstName) {
      model.name = firstName;
    }

    return model;
  });

  return (
    <div className={styles.home_gallery_container}>
      <h1 className={styles.home_gallery_title}>
        Explore <span>AI Characters</span>
      </h1>
      <div className={styles.home_galery_nav}>
        <HeaderNav />
      </div>
      <div className={styles.home_gallery_wrapper}>
        {models.map((model: IModel) => (
          <GalleryItemFactory
            model={model}
            variant="full_meta"
            key={model.id}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeGallery;
