import { LinkFactory } from "@shared/ui/Link";
import styles from "./styles.module.css";
import { IModel, ROUTES } from "@shared/interfaces";
import { IUserState, useUserStore } from "@entities/user";
import { useState } from "react";
import ModelCardSkeleton from "./ModelCardSkeleton";

interface IProps {
  model: any;
}

const ModelCard: React.FC<IProps> = ({ model }: IProps) => {
  const user = useUserStore((state: IUserState) => state.user);
  const [isImgLoded, setImgLoded] = useState<boolean>(true);

  const onLoad = () => {
    setImgLoded(false);
  }

  const classes = [styles.model_wrapper];

  if (!user.plan) {
    //classes.push(styles.model_container_blur);
  }

  return (
    <div className={`${styles.model_container} ${isImgLoded? styles.img_loded:""}`}>
      <div className={styles.model_card_skeleton}>
        <ModelCardSkeleton />
      </div>
      <LinkFactory
        label=""
        path={`${ROUTES.GALLERY}?preview=${model.id}`}
        className={[styles.link_typeof_div]}
      >
        <div className={classes.join(" ")}>
          <img src={model.image_url} alt={`model-${model.id}`} onLoad={onLoad}/>
        </div>
      </LinkFactory>
    </div>
  );
};

export default ModelCard;
